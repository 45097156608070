import {Capacitor} from '@capacitor/core'
import moment from "moment";
import _cloneDeep from "lodash/cloneDeep";
import {alertController} from "@ionic/vue";
import { useGtm } from '@gtm-support/vue-gtm';

const isProd = process.env.NODE_ENV === 'production'

export default {
  /*
   * Check if running in a production environment
   */
  isProd,

  /*
   * Get constants defined in .env through the Vue Service
   * @param {String} constant - name of constant to be returned
   */
  env(constant) {
    return process.env[`VUE_APP_${constant}`]
  },

  /*
   * Write message to console
   * @param {String} msg - message of the log
   */
  log(msg) {
    notify('log', msg)
  },

  /*
   * Write warning to console
   * @param {String} msg - message of the warning
   */
  warn(msg) {
    notify('warn', msg)
  },

  /*
   * Write error to console
   * @param {String} msg - message of the error
   */
  err(msg) {
    notify('error', msg)
  },

  /*
   * Check is reviews on apps stores feature is enabled
   */
  storeReviewsEnabled() {
    return this.env('STORE_REVIEWS_ENABLED') === 'true'
  },

  /*
   * Return iOS app id
   */
  iOSAppId() {
    return this.env('IOS_APP_ID')
  },

  /*
   * Check is the app is running into a web environment
   */
  isWeb() {
    return Capacitor.platform === 'web'
  },

  /*
   * Check is the app is running into an iOS environment
   */
  isIOS() {
    return Capacitor.platform === 'ios'
  },

  isNativePlatform() {
    return Capacitor.isNativePlatform()
  },

  /*
   * Format currency value
   */
  formatCurrency(value) {
    var formatter = new Intl.NumberFormat('it-IT', {
      style: 'currency',
      currency: 'EUR',
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
    });
    return formatter.format(value);
  },
  formatNumber(value) {
    var formatter = new Intl.NumberFormat('it-IT', {
      maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
    });
    return formatter.format(value);
  },

  /*
   * First letter uppercase
   */
  ucFirstWords(phrase) {
    if (!phrase.length) return "";
    let lower = phrase.toLowerCase();
    const words = lower.split(" ");
    return words.map((word) => {
      if (!word.length) return "";
      return word[0].toUpperCase() + word.substring(1);
    }).join(" ");
  },

  formatAddress(data, hasBreak = true) {
    if(!data) return '';
    const br = hasBreak ? '<br>' : ' ';
    return this.ucFirstWords(data.address) + br
      + data.postalCode + ' '
      + this.ucFirstWords(data.locality)
      + ' (' + data.province + ')';
  },

  formatDate(date) {
    if(date.indexOf('-') > 0)
    {
      const res = date.split('-');
      return res[2] + '-' + res[1] + '-' + res[0];
    }

    return '';
  },

  getValidateDate(date, format = 'YYYY-MM-DD', split = false) {
    let a = moment(date, 'YYYY-MM-DD');

    if (a.isValid() && !split) {
      return a.locale('it').format(format);
    } else if (split && date && date.indexOf('-') > 0) {
      const splitted = this.formatDate(date);
      if (moment(splitted, 'YYYY-MM-DD').isValid())
        return moment(splitted, 'YYYY-MM-DD').locale('it').format(format);

      return splitted;
    }
    return date;
  },

  validRequired(text) {
    if (text === null || text === '') return false;
    const re = /\S{1,}/;
    return re.test(text);
  },

  onlyNumberValidate(text) {
    if (text === null || text === '') return false;
    const re = /^\d+$/;
    return re.test(text);
  },

  fillChartLabels(labels, values, minLabels, momentType, commodity = 'electricity') {
    let newLabels = _cloneDeep(labels);
    let newValues = _cloneDeep(values);
    if (labels.length < minLabels) {
      let lastLabel = labels[labels.length - 1];
      const lengthFor = minLabels - labels.length;
      for (let i = 1; i <= lengthFor; i++) {
        let newLabel = moment(lastLabel, 'YYYY-MM-DD').locale('it').add(i, momentType).format('YYYY-MM-DD');
        newLabels.push(newLabel)
        newValues.f1.push(0);
        if (commodity === 'electricity') {
          newValues.f2.push(0);
          newValues.f3.push(0);
        }
      }
    }
    return {newLabels, newValues}
  },

  getPeriodsBetweenDatesfunction(startDate, endDate, period = 'days') {
    let now = startDate.clone(), dates = [];

    while (now.isSameOrBefore(endDate)) {
      dates.push(now.clone());
      now.add(1, period);
    }
    return dates;
  },

  fiscalCodeValidate(cfins) {
    const cf = cfins.toUpperCase();

    const set1 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const set2 = "ABCDEFGHIJABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const setpari = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const setdisp = "BAKPLCQDREVOSFTGUHMINJWZYX";
    let s = 0;
    for (let i = 1; i <= 13; i += 2)
      s += setpari.indexOf(set2.charAt(set1.indexOf(cf.charAt(i))));
    for (let i = 0; i <= 14; i += 2)
      s += setdisp.indexOf(set2.charAt(set1.indexOf(cf.charAt(i))));
    if (s % 26 != cf.charCodeAt(15) - 'A'.charCodeAt(0)) return false;

    return true;
  },

  vatNumberValidate(pi) {
    if (pi === '') return false;
    else if (!/^[0-9]{11}$/.test(pi)) return false;
    else {
      const validi = "0123456789";
      for (let i = 0; i < 11; i++) {
        if (validi.indexOf(pi.charAt(i)) === -1) return false;
      }

      let s = 0;
      for (let i = 0; i <= 9; i += 2) {
        s += pi.charCodeAt(i) - '0'.charCodeAt(0);
      }
      for (let i = 1; i <= 9; i += 2) {
        var c = 2 * (pi.charCodeAt(i) - '0'.charCodeAt(0));
        if (c > 9) c = c - 9;
        s += c;
      }
      const controllo = (10 - s % 10) % 10;
      if (controllo !== pi.charCodeAt(10) - '0'.charCodeAt(0)) return false;
      else return true;
    }
  },

  emailValidate(email){
    let filter = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return filter.test(email);
  },

  formatDecimalNumber(value, fractionDigits = 2) {

    var formatter = new Intl.NumberFormat('it-IT', {
      maximumFractionDigits: fractionDigits, // (causes 2500.99 to be printed as $2,501)
    });
    return formatter.format(value);
    /*
    const decimal = Number(value).toFixed(fractionDigits);
    const integer = Number(value).toFixed(0);
    if(Number(decimal) !== Number(integer)){
      return this.formatNumber(decimal);
    }
    return integer;

     */
  },

  getInvoicePaymentStatus(invoice){
    /*
    const self = this;
    let installmentToBePaid  = false;
    if(installments.length && Number(invoice.outstandingAmount) > 0 && invoice.paymentTerms !== 'Addebito automatico' ){
      installmentToBePaid = installments.some((installment) => {
        let status = self.getInstallmentStatus(installment);
        return status === 0;
      })
    }
    if(installmentToBePaid) return 0; // DA PAGARE
     */
    return invoice.paymentLevel;

    /*
    if(invoice.paymentTerms === 'Addebito automatico' && Number(invoice.outstandingAmount) > 0) return 1; // IN PAGAMENTO
    else if(invoice.paymentTerms !== 'Addebito automatico' && invoice.toBePaid) return 0; // DA PAGARE
    return 2; // PAGATA
     */
  },

  getInstallmentStatus(installment){
    const dueDate = moment(installment.dueDate, 'YYYY-MM-DD');
    const now =  moment();
    if(now.isSameOrAfter(dueDate) && installment.toBePaid) return 0; // DA PAGARE
    else if(now.isBefore(dueDate)) return 1; // IN PAGAMENTO
    return 2; // PAGATA
  },

  manageCatchError(error){
    console.error({'name': 'manageCatchError', 'errore': error});
    window.location.href = '/errore';
  },

  async showErrorMessage(message, button) {
    var description = '';
    if(!message || typeof message === 'undefined'){
      message = 'Ops! Qualcosa è andato storto.';
      description = 'Se il problema persiste, contattare il servizio clienti.';
    }
    if(!button || typeof button === 'undefined'){
      button = 'Chiudi';
    }
    if(!description && message)
    {
      description = message;
      message = 'Attenzione!';
    }

    const alert = await alertController.create({
      message: '<i class="icon sgri-close"></i> ' +
          '<h3 class="title">'+message+'</h3> ' +
          '<div class="message">'+description+'</div>',
      buttons: [
        {
          text: button,
          role: 'confirm',
        },
        {
          text: 'x',
          role: 'cancel',
          cssClass: 'alert-button-cancel',
        },
      ],
      cssClass: 'errorPopup danger',
      backdropDismiss: false,
    });

    await alert.present();

    return alert;
  },

  async showWarningMessage(message, button) {
    var description = '';
    if(!message || typeof message === 'undefined'){
      message = 'Ops! Qualcosa è andato storto.';
      description = 'Se il problema persiste, contattare il servizio clienti.';
    }
    if(!button || typeof button === 'undefined'){
      button = 'Chiudi';
    }
    const alert = await alertController.create({
      message: '<i class="icon sgri-not-paid"></i> ' +
          '<h3 class="title">'+message+'</h3> ' +
          '<div class="message">'+description+'</div>',
      buttons: [
        {
          text: button,
          role: 'confirm',
        },
        {
          text: 'x',
          role: 'cancel',
          cssClass: 'alert-button-cancel',
        },
      ],
      cssClass: 'errorPopup warning',
      backdropDismiss: false,
    });

    await alert.present();

    return alert;
  },

  randomIntFromInterval(min, max) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min)
  },

  trackEventMeta(event, obj)
  {
    if(typeof window.fbq !=='undefined'){
      window.fbq('track', event, obj);
    }
  },

  trackEventCustomMeta(event, obj)
  {
    if(typeof window.fbq !=='undefined'){
      window.fbq('trackCustom', event, obj);
    }
  },

  trackEventGA4(obj){
    const gtm = useGtm();
    if(typeof gtm !== 'undefined')
    {
      gtm.trackEvent(obj);
    }
  },

  isJsonString(str){
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
}

/*
 * Abstract notifier
 * @param {String} method - type of notification
 * @param {String} msg - message to be written
 */
function notify(method, msg) {
  if (console && !isProd) {
    console[method](`[MYSGR] ${msg}`)
  }
}